! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

jQuery(document).ready(function($) {
	function initializeMenu() {
		// Mobile menu
		var $mobileMenuTrigger = $(".w-nav-button");
		var $mobileMenu = $(".mobile-navigation-menu");

		if ($mobileMenu.length == 0) {
			return;
		}

		$mobileMenu.mmenu({
			extensions: ["position-right"],
			navbar: {
				add: true,
				title: ""
			},
			screenReader: {
				aria: false,
				text: true
			}
		});

		var API = $mobileMenu.data("mmenu");

		$mobileMenuTrigger.click(function() {
			API.open();
			$mobileMenuTrigger.addClass("opened");

			return false;
		});

		$mobileMenu.data('mmenu').bind('closed', function() {
			$mobileMenuTrigger.removeClass("opened");
		});
	}

	initializeMenu();

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 1000 ) {
		$(".inside-page-wrapper").fitVids({ ignore: "nofit" });
	}

	// Add dropdown class to main nav items
	$(".dropdown-wrap:has('.dropdown-container')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".dropdown-wrap").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".dropdown-wrap").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// Link with image
	$(".inside-page-wrapper a").has("img").css("box-shadow", "none");
	$(".inside-page-wrapper a").has("img").hover().css("background-color", "transparent");

	// Search toggling
	$("#search-toggle").click(function() {
		if ( $(".search-container-top").height() > 0 ) {
			$(".search-container-top").css("max-height", "0");
		}
		else {
			$(".search-container-top").css("max-height", "89px");
		}
	});

	$("#search-close").click(function() {
		$(".search-container-top").css("max-height", "0");
	});

	/**
	* ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	Webflow.require('ix').init([
		{slug: "dropdown-hover",name: "Dropdown Hover",value: {style: {},triggers: [{type: "hover",selector: ".dd-list",descend: true,stepsA: [{wait: "50ms"},{display: "block",opacity: 1,height: "auto",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}],stepsB: [{display: "none",opacity: 0,height: "0px",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}},
		{slug: "recipe-dropdown",name: "Recipe Dropdown",value: {style: {},triggers: [{type: "click",selector: ".recipe-filter-dd-scroll-wrap",stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "search-dropdown",name: "Search Dropdown",value: {style: {},triggers: [{type: "click",selector: ".header-search-container",stepsA: [{height: "auto",transition: "height 300ms ease 0"}],stepsB: [{height: "0px",transition: "height 300ms ease 0"}]}]}},
		{slug: "search-push-down-rotator",name: "Search Push Down Rotator",value: {style: {},triggers: [{type: "click",stepsA: [{height: "auto",transition: "height 300ms ease 0"}],stepsB: [{height: "0px",transition: "height 300ms ease 0"}]}]}},
		{slug: "close-push-down-rotator-search",name: "Close Push Down Rotator Search",value: {style: {},triggers: [{type: "click",selector: ".search-container-push-down-rotator",stepsA: [{height: "0px",transition: "height 500ms ease 0"}],stepsB: []}]}},
		{slug: "shop-arrow",name: "Shop Arrow",value: {style: {opacity: 0,x: "-30px",y: "0px",z: "0px"},triggers: [{type: "hover",stepsA: [{opacity: 1,transition: "opacity 600ms ease 0, transform 800ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "nav-dropdown",name: "Nav Dropdown",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px",transition: "height 200 ease 0, opacity 200 ease 0"},{opacity: 1,height: "auto",transition: "height 300ms ease 0, opacity 300ms ease 0"}],stepsB: [{height: "0px",transition: "height 200 ease 0"}]}]}},
		{slug: "heart-clicked",name: "Heart Clicked",value: {style: {},triggers: [{type: "click",selector: ".heart-save",stepsA: [{display: "block",opacity: 1,width: "20px",height: "20px",transition: "width 200 ease 0, opacity 200 ease 0, height 200 ease 0"}],stepsB: [{display: "none",opacity: 0,width: "0px",transition: "width 200 ease 0, opacity 200 ease 0"}]},{type: "click",selector: ".hearthover",stepsA: [{display: "none",opacity: 0,width: "0px",transition: "width 200 ease 0, opacity 200 ease 0"}],stepsB: [{display: "block",opacity: 1,width: "20px",transition: "opacity 200 ease 0, width 200 ease 0"}]}]}}
	]);
});

$(window).scroll(function() {
	$('.header-section').toggleClass("sticky", $(this).scrollTop() > 1);
});

$(document).on("select2:open", () => {
	document.querySelector(".select2-search__field").focus();
});
